import React, { ReactNode } from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import { Paragraph } from "../../text/paragraph/paragraph";
import { cn } from "../../../utils/shorthands";
import * as scss from "./badge.scss";

export type BadgeTypes =
	| "positive"
	| "neutral"
	| "warning"
	| "negative"
	| "subtle"
	| "highlight1"
	| "highlight2"
	| "highlight3";

export type BadgeProps = {
	/** Text displayed inside the badge */
	children: ReactNode;
	/** Controls the color of the badge - defaults to "neutral" */
	type?: BadgeTypes;
	/** Controls the visual style of the badge  - defaults to "dark" */
	variant?: "dark" | "light";
	/** Controls the size of the badge - defaults to "medium" */
	size?: "small" | "medium" | "large";
} & DataObserveKey &
	VisualComponent;

export function Badge(props: BadgeProps): JSX.Element {
	const { children, type = "neutral", variant = "dark", size = "medium", className, style } = props;

	return (
		<div
			data-component="badge"
			data-observe-key={props["data-observe-key"]}
			className={cn(scss.badge, type && scss[type], scss[variant], className)}
			style={style}
		>
			{React.Children.map(children, (c) =>
				typeof c === "string" || typeof c === "number" ? (
					<Paragraph emphasis="medium" size={sizeMap[size]} lineHeight="single-line">
						{c}
					</Paragraph>
				) : (
					c
				)
			)}
		</div>
	);
}

const sizeMap = {
	small: "xSmall",
	medium: "small",
	large: "medium",
} as const;
